// @flow
import * as React from "react";
import { useEffect, useState } from "react";
import axiosconfig from "../../config/axios-config";
import { useRef } from "react";
import { validateEmail } from "../../utility/helper";
import { companyId } from "../../next.config";

export default function SubscribeSection() {
  const [subscribeSection, setSubscribeSection] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();

  function handleInputChange() {
    setFirstName(firstNameRef.current.value);
    setLastName(lastNameRef.current.value);
    setEmail(emailRef.current.value);
    if (validateEmail(emailRef.current.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }

  useEffect(() => {
    getSubscribeSection();
  }, []);

  async function getSubscribeSection() {
    const sections = await axiosconfig.get("website/homepagesections");
    const pageSections = await sections.data.data[0]?.newsletterData;
    setSubscribeSection(pageSections);
  }

  async function handleSubscriptionPost(event) {
    event.preventDefault();
    if (
      firstNameRef.current.value &&
      lastNameRef.current.value &&
      emailRef.current.value &&
      validateEmail(emailRef.current.value)
    ) {
      setSaveProgress(true);
      axiosconfig
        .post("website/newslettersubscription", {
          companyId: companyId,
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          email: emailRef.current.value,
        })
        .then(() => {
          firstNameRef.current.value = "";
          lastNameRef.current.value = "";
          emailRef.current.value = "";
          setSuccessMessage(true);
          setSaveProgress(false);
          setTimeout(() => setSuccessMessage(false), 5500);
        })
        .catch(() => {
          setFailureMessage(true);
          setTimeout(() => setFailureMessage(false), 5500);
          setSaveProgress(false);
        });
    }
  }

  return (
    <section
      className="subscribe-section top-24"
      style={{
        backgroundImage: `url(${
          subscribeSection?.image ? subscribeSection?.image : "url(/bg.jpg)"
        })`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="section-heading section-heading-white">
              {subscribeSection.subTitle?.length > 0 && (
                <h4>{subscribeSection.subTitle}</h4>
              )}
              <h2>{subscribeSection.title}</h2>
              <h4>{subscribeSection.description}</h4>
              <div className="newsletter-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control text-white bg-transparent rounded-0"
                        type="text"
                        name="firstName"
                        placeholder="Your First Name"
                        ref={firstNameRef}
                        onKeyUp={handleInputChange}
                        onChange={handleInputChange}
                        onPaste={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control text-white bg-transparent rounded-0"
                        type="text"
                        name="lastName"
                        placeholder="Your Last Name"
                        ref={lastNameRef}
                        onKeyUp={handleInputChange}
                        onChange={handleInputChange}
                        onPaste={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <form>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                    ref={emailRef}
                    onKeyUp={handleInputChange}
                    onChange={handleInputChange}
                    onPaste={handleInputChange}
                  />
                  <button
                    type="submit"
                    onClick={handleSubscriptionPost}
                    disabled={
                      !firstName ||
                      !lastName ||
                      !email ||
                      !emailValid ||
                      saveProgress
                    }
                  >
                    {saveProgress && (
                      <span className="css-spinner sm icon-fix mr-2"></span>
                    )}
                    Sign Up Now!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed-message success ${successMessage ? "shown" : ""}`}>
        <i className="far fa-check-circle mr-2"></i>
        Thank You ! Your have been subscribed successfully to our newsletter.
      </div>
      <div className={`fixed-message error ${failureMessage ? "shown" : ""}`}>
        <i className="far fa-times-circle mr-2"></i>
        Oops!! We couldn't process your request, Please try again later.
      </div>
    </section>
  );
}
