// @flow
import * as React from "react";
import Slider from "react-slick";
import { useEffect, useState, Fragment } from "react";
import axiosconfig from "../../config/axios-config";
import Link from "next/link";
import { sanitizeProjectURL } from "../../utility/helper";

export default function ClientSection() {
  const [partnerLogo, setpartnerLogo] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [pageContainer, setPageContainer] = useState([]);
  useEffect(() => {
    panersDataFn();
    getPageContentData();
    getPageContainerData();
  }, []);

  async function getPageContentData() {
    const respone = await axiosconfig.get("website/pagecontainer", {
      params: {
        pageNumber: 0,
      },
    });
    const pages = await respone.data.data;
    setPageContent(pages);
  }

  async function getPageContainerData() {
    const respone = await axiosconfig.get("website/pagemodule", {
      params: {
        pageNumber: 0,
      },
    });
    const pages = await respone.data.data;
    setPageContainer(pages);
  }

  async function panersDataFn() {
    const res = await axiosconfig.get("website/partnerlogo");

    const alldata = await res.data;
    setpartnerLogo(alldata.data?.sort((a, b) => a.order - b.order));
  }

  var logoShow = [];
  partnerLogo.forEach((logo) => {
    if (logo.isHomeShown == true) {
      logoShow.push(logo);
    }
  });

  // const clientSlider = {
  //   dots: false,
  //   infinite: false,
  //   speed: 1000,
  //   arrows: true,
  //   slidesToShow: 4,
  //   autoplay: false,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 500,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="client-section">
      <div className="container">
        <div className="client-wrap d-flex justify-content-center align-items-center flex-wrap">
          {logoShow.map((log) => {
            return (
              <Fragment key={log.id}>
                <div className={`client-item`}>
                  {log?.isExternalLink && !log?.externalLink && (
                    <figure>
                      <img width={120} alt={log.title} src={log.logo} />
                    </figure>
                  )}
                  {log?.isExternalLink && log?.externalLink && (
                    <a href={log.externalLink} target="_blank" rel="noreferrer">
                      <figure>
                        <img width={120} alt={log.title} src={log.logo} />
                      </figure>
                    </a>
                  )}
                  {!log?.isExternalLink &&
                    (log?.pageContainerId?.length > 0 ? (
                      pageContent.map((pagecontiner) => {
                        return (
                          <Fragment key={pagecontiner.id}>
                            {pagecontiner?.id === log?.pageContainerId &&
                            pagecontiner?.pageSubModuleId?.length > 0 ? (
                              pageContainer.map((pageModule) => {
                                return (
                                  <Fragment key={pageModule.id}>
                                    {pageModule?.id ===
                                      pagecontiner?.pageModuleId && (
                                      <Link
                                        href={`/${sanitizeProjectURL(
                                          pageModule?.name
                                        )}/${pagecontiner?.pageSEO.pageURL}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <a>
                                          <img
                                            width={120}
                                            alt={log.title}
                                            src={log.logo ? log.logo : ""}
                                          />
                                        </a>
                                      </Link>
                                    )}
                                  </Fragment>
                                );
                              })
                            ) : pagecontiner?.id === log?.pageContainerId ? (
                              <Link
                                href={`/${pagecontiner?.pageSEO?.pageURL}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <a>
                                  <img
                                    width={120}
                                    alt={log.title}
                                    src={log.logo ? log.logo : ""}
                                  />
                                </a>
                              </Link>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        );
                      })
                    ) : (
                      <figure>
                        <img
                          width={120}
                          alt={log.title}
                          src={log.logo ? log.logo : ""}
                        />
                      </figure>
                    ))}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
